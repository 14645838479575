'use client'; // Error boundaries must be Client Components

import { NextLink } from '@/components/NextLink/NextLink';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { captureUnderscoreErrorException } from '@sentry/nextjs';
import { useEffect } from 'react';

export default function Error({
   error,
   reset,
}: {
   error: Error & { digest?: string };
   reset: () => void;
}) {
   useEffect(() => {
      captureUnderscoreErrorException({ err: error });
   }, [error]);

   return (
      <Flex
         alignItems="center"
         justifyContent="center"
         minH="var(--app-height)"
         flexDir="column"
         gap={2}
      >
         <Heading>Something went wrong.</Heading>
         <Text color="background.primary" fontWeight={600}>
            <Button onClick={reset} size="xs">
               Try again
            </Button>
         </Text>
         <Flex flexDir="column" textAlign="center">
            or <NextLink href="/">Go to home</NextLink>
         </Flex>
      </Flex>
   );
}
